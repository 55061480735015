
const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 21, 21, -1],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();

brd1.options.layer['image'] =14;

//Title and subtitle
var title1 = brd1.create('text', [10, 20, 'Coefficients of a Polynomial'],{display:'internal', highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}});
var txt2 = brd1.create('text', [7, 10, 'Coefficient of Intermediate Term?'],{display:'internal',highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt3 = brd1.create('text', [7, 7, 'Coefficient of Constant Term ?'],{display:'internal',highlight:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);

//Variables
var a = 18;
var b = -17;
var c = 9;
var o = 2;
var j =1;
var txt_b = '- 17';
var txt_c = '+ 9';
brd1.create('image', ['/assets/oneR.svg', [9, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
brd1.create('image', ['/assets/twoR.svg', [11.5, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
brd1.create('image', ['/assets/threeR.svg', [14, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});

//Images
var shuffle = brd1.create('image', ['/assets/shuffle.svg', [8, 2.5], [2 , 2]], {visible: true, fixed: true, highlight:true});
var tryMe = brd1.create('image', ['/assets/test.svg', [15, 2.5], [2 , 2]], {visible: true, fixed: true, highlight:true});

var ok1 = brd1.create('image', ['/assets/check.svg', [18, 12.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});
var ok2 = brd1.create('image', ['/assets/check.svg', [18, 9.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});
var ok3 = brd1.create('image', ['/assets/check.svg', [18, 6.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});

var cross1 = brd1.create('image', ['/assets/cross.svg', [18, 12.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});
var cross2 = brd1.create('image', ['/assets/cross.svg', [18, 9.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});
var cross3 = brd1.create('image', ['/assets/cross.svg', [18, 6.5], [1.5 ,1.5]], {visible: false, fixed: true, highlight:false});

//Shuffle function
shuffle.on('down',function(){

    ok1.setAttribute({visible: false});
    cross1.setAttribute({visible: false});
    ok2.setAttribute({visible: false});
    cross2.setAttribute({visible: false});
    ok3.setAttribute({visible: false});
    cross3.setAttribute({visible: false});
    let magicGraph = brd1.containerObj;
    let inputFields = magicGraph.querySelectorAll("input");
        for (let inputField of inputFields) {
              inputField.value = "";}
    a=0;
    while(a == 0){
        a = Math.round(Math.random()*100-50)+9;
    }
    b=0;
    while(b == 0){
        b = Math.round(Math.random()*100 -50)+9;

        if(b>= 0){
            txt_b = '+ ' + b;
        }
        else{
            txt_b = b;
        }
    }

    c=0;
    while(c == 0){
        c = Math.round(Math.random()*100 -50);

        if(c>= 0){
            txt_c = '+ ' + c;
        }
        else{
            txt_c = c;
        }

    }

    o=0;
    while(o < 2){
        o = Math.round(Math.random()*9);

    }
     j = Math.round(Math.random()*5)+2;
     if(j==o)
     {return j = o-1;}
     else {return j}
    //coefficient1.Value() = 'ppp'; // I want to reset the input values
});


shuffle.setLabel('Step 3: Tap to shuffle')
shuffle.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle.on('over', function () {this.label.setAttribute({visible:true});});
shuffle.on('out', function () {this.label.setAttribute({visible:false});});

tryMe.setLabel('Step 2: Tap to check your answer')
tryMe.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
tryMe.on('over', function () {this.label.setAttribute({visible:true});});
tryMe.on('out', function () {this.label.setAttribute({visible:false});});


var polynomial1 = brd1.create('text', [6.5, 16, function(){return 'f(x) = '+ a + ' x^' + o  }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/500.)}},);
var polynomial2 = brd1.create('text', [10.75, 16, function(){return  ' ' +txt_b + ' x^'+ j }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/500.)}},);
var polynomial3 = brd1.create('text', [13.5, 16, function(){return ' '+ txt_c }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/500.)}},);

var txt1 = brd1.create('text', [7, 13, 'Coefficient of Highest Power Term?'],{display:'internal', highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);

var coefficient1 = brd1.create('input', [15, 13, '', ''], {fixed: true, cssStyle: 'cursor:pointer;fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
coefficient1.setLabel('Step 1(a): Enter your answer here')
coefficient1.label.setAttribute({visible:false, offset:[0, -30], CssStyle:'fontFamily:Oswald', fontSize:16});
coefficient1.on('over', function () {this.label.setAttribute({visible:true});});
coefficient1.on('out', function () {this.label.setAttribute({visible:false});});
var coefficient2 = brd1.create('input', [15, 10, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
coefficient2.setLabel('Step 1(b): Enter your answer here')
coefficient2.label.setAttribute({visible:false, offset:[0, -30], CssStyle:'fontFamily:Oswald', fontSize:16});
coefficient2.on('over', function () {this.label.setAttribute({visible:true});});
coefficient2.on('out', function () {this.label.setAttribute({visible:false});});

var order = brd1.create('input', [15, 7, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
order.setLabel('Step 1(c): Enter your answer here')
order.label.setAttribute({visible:false, offset:[0, -30], CssStyle:'fontFamily:Oswald', fontSize:16});
order.on('over', function () {this.label.setAttribute({visible:true});});
order.on('out', function () {this.label.setAttribute({visible:false});});
var loc = function(){if(o >=j){return b} else {return a}}
var coeff = function(){if(o >=j){return a} else {return b}}
function check(){

    if(coefficient1.Value() == coeff()){

        ok1.setAttribute({visible: true});
        cross1.setAttribute({visible: false});

    }

    else{
        ok1.setAttribute({visible: false});
        cross1.setAttribute({visible: true});
    }


    if(coefficient2.Value() == loc()){

        ok2.setAttribute({visible: true});
        cross2.setAttribute({visible: false});

    }

    else{
        ok2.setAttribute({visible: false});
        cross2.setAttribute({visible: true});
    }

    if(order.Value() == c){

        ok3.setAttribute({visible: true});
        cross3.setAttribute({visible: false});

    }

    else{
        ok3.setAttribute({visible: false});
        cross3.setAttribute({visible: true});
    }

    coefficient1.setAttribute({display: '0'});
}


tryMe.on('down', check);

//brd1.create('text', [15.5 , 4 , '<button onclick="check()">Try!</button>'] , {fixed: true});


brd1.unsuspendUpdate();
}
}
export default Boxes;
