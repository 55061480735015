<template>
  <div>
    <h3 ref="intro">
      Terms of a Polynomial
    </h3>
    <p>
      Let's start with recalling that a polynomial is an algebraic expression containing one or multiple terms. As an example &mdash; consider the polynomial expression
      shown below.
      $$p(x) = 4 + 4 x + x^2$$
      This polynomial expression contains three terms: \(4\), \(4 x\), and \(x^2\).  Each term consists of
      an integer power of variable \(x\) multiplied with a constant coefficient. For example, the first term consists of 4 multiplied with \(x^0\) (recall \(x^0 =1\) so this is the constant term).
      The second term consists of 4 multiplied with \(x^1\) (note that \(x^1\) is commonly written as \(x\)).
      The last term consists of 1 multiplied with \(x^2\).
    </p>
    <h3 ref="formal">
      Coefficients of a Polynomial
    </h3>
    <p>
      The exponent of the highest power term in the polynomial is called the degree of the polynomial.
      The degree of a polynomial is also known as the order of the polynomial. For example, in the polynomial expression
      \(p(x) = 4+ 4 x + x^2\), the highest power term is \(x^2\). The exponent of this term is 2. Thus, the degree of this polynomial is 2.
    </p>
    <h3 ref="playgraph">
      HoverGraph | Coefficients of a Polynomial
    </h3>
    <p>
      Through this MagicGraph, students will learn about the coefficients of a polynomial: what are they and how to find them. So, let's get started.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a polynomial f(x) that consists of three terms.
      The three terms of the polynomial are marked as 1, 2 or 3.
      Your task is to identify the coefficients of the three terms of the polynomial.
      Enter your answers in the blue boxes provided.
    </p>
    <h5> To Explore:</h5>
    <p>
      Each time you tap on the shuffle button, the MagicGraph generates a new polynomial.
      Enter your answers for the three questions in the blue boxes.
      Tap on the test button to check your answer. Practice as many times as you want.
    </p>
    <v-responsive
      :aspect-ratio="1"
    >
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ForcesStresses',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Coefficients of a Polynomial';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Terms of a Polynomial', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Coefficients of a Polynomial', img:'/assets/number-2.svg', action: () => this.goto('formal')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Coefficients of a Polynomial',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
